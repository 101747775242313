.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in;

  div {
    div::before {
      margin: 5px 0;
      content: '\2022';
      margin-right: 5px;
      display: inline-block;
      width: 1em;
      font-weight: bold;
    }
  }
}

.accordion-content.open {
  max-height: 500px; /* Adjust the maximum height as needed */
}

.accordion-content-cs {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in;
}

.accordion-content-cs.open {
  max-height: fit-content;
}
