@import './variables.scss';

.sline {
  img {
    display: inline;
  }
  color: #0a0337;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.28px;
}

.payment-details {
  img {
    display: inline;
  }
  padding-left: 20px;
  list-style: disc;
  li {
    color: #0a0337;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.28px;
    padding-bottom: 5px;
  }
}

.payment-details-work {
  img {
    display: inline;
  }
  padding-left: 20px;
  list-style: number;
  li {
    color: #0a0337;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.28px;
    padding-bottom: 5px;
  }
}

.payment-details-fee {
  img {
    display: inline;
  }
  padding-left: 20px;
  list-style: disc;
  li {
    color: #0a0337;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
}

.c_print img,
.c_agent img,
.c_mail img {
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.c_print,
.c_agent,
.c_mail {
  padding: 8px;
  font-size: 13px;
  line-height: 16px;
}

.grid-1 {
  @apply grid;
  @apply grid-cols-1;
}

.grid-2 {
  @apply grid;
  @apply grid-cols-2;
}

.worldpay-dialog-box {
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border: 8px solid #e4e3de;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 5px;
  text-align: left;
}

.wp-text {
  font-size: 12px;
}

.wp-override {
  ul {
    list-style: disc;
    margin-top: 0.3rem;
    padding-left: 2rem;
  }
}

.text-accent-translation {
  @apply text-accent;
}
