td:nth-child(2) {
    text-align: right;
    color: #0A0337;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--custom-font-name);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

td:first-child {
    text-align: left;
    color: 0A0337;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: var(--custom-font-name);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}