@import './variables.scss';

i.gpfs-flag {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 20px;
  vertical-align: bottom;
  margin: 0em 0.5em 0em 0em;
  text-decoration: inherit;
  speak: none;
  font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url($base_url + '/images/flags.png') no-repeat 20px 20px;
}

i.gpfs-flag.ala {
  width: 25px;
  height: 25px;
  background-position: -5px -5px;
}

i.gpfs-flag.afg {
  width: 25px;
  height: 25px;
  background-position: -40px -5px;
}

i.gpfs_flag.AFRICAN_UNION_K_OL {
  width: 25px;
  height: 25px;
  background-position: -75px -5px;
}

i.gpfs-flag.alb {
  width: 25px;
  height: 25px;
  background-position: -110px -5px;
}

i.gpfs-flag.dza {
  width: 25px;
  height: 25px;
  background-position: -145px -5px;
}

i.gpfs-flag.and {
  width: 25px;
  height: 25px;
  background-position: -180px -5px;
}

i.gpfs-flag.ago {
  width: 25px;
  height: 25px;
  background-position: -215px -5px;
}

i.gpfs-flag.aia {
  width: 25px;
  height: 25px;
  background-position: -250px -5px;
}

i.gpfs-flag.atg {
  width: 25px;
  height: 25px;
  background-position: -285px -5px;
}

i.gpfs-flag.arg {
  width: 25px;
  height: 25px;
  background-position: -320px -5px;
}

i.gpfs-flag.arm {
  width: 25px;
  height: 25px;
  background-position: -355px -5px;
}

i.gpfs-flag.abw {
  width: 25px;
  height: 25px;
  background-position: -390px -5px;
}

i.gpfs-flag.aus {
  width: 25px;
  height: 25px;
  background-position: -425px -5px;
}

i.gpfs-flag.aut {
  width: 25px;
  height: 25px;
  background-position: -460px -5px;
}

i.gpfs-flag.aze {
  width: 25px;
  height: 25px;
  background-position: -495px -5px;
}

i.gpfs-flag.bhr {
  width: 25px;
  height: 25px;
  background-position: -5px -40px;
}

i.gpfs-flag.bhs {
  width: 25px;
  height: 25px;
  background-position: -40px -40px;
}

i.gpfs-flag.brb {
  width: 25px;
  height: 25px;
  background-position: -75px -40px;
}

i.gpfs-flag.blz {
  width: 25px;
  height: 25px;
  background-position: -110px -40px;
}

i.gpfs-flag.bmu {
  width: 25px;
  height: 25px;
  background-position: -145px -40px;
}

i.gpfs-flag.blr {
  width: 25px;
  height: 25px;
  background-position: -180px -40px;
}

i.gpfs-flag.bel {
  width: 25px;
  height: 25px;
  background-position: -215px -40px;
}

i.gpfs-flag.ben {
  width: 25px;
  height: 25px;
  background-position: -250px -40px;
}

i.gpfs-flag.btn {
  width: 25px;
  height: 25px;
  background-position: -285px -40px;
}

i.gpfs-flag.bol {
  width: 25px;
  height: 25px;
  background-position: -320px -40px;
}

i.gpfs-flag.bwa {
  width: 25px;
  height: 25px;
  background-position: -355px -40px;
}

i.gpfs-flag.bih {
  width: 25px;
  height: 25px;
  background-position: -390px -40px;
}

i.gpfs-flag.bra {
  width: 25px;
  height: 25px;
  background-position: -425px -40px;
}

i.gpfs-flag.vgb {
  width: 25px;
  height: 25px;
  background-position: -460px -40px;
}

i.gpfs-flag.brn {
  width: 25px;
  height: 25px;
  background-position: -495px -40px;
}

i.gpfs-flag.bgr {
  width: 25px;
  height: 25px;
  background-position: -5px -75px;
}

i.gpfs-flag.bfa {
  width: 25px;
  height: 25px;
  background-position: -40px -75px;
}

i.gpfs-flag.bdi {
  width: 25px;
  height: 25px;
  background-position: -75px -75px;
}

i.gpfs-flag.khm {
  width: 25px;
  height: 25px;
  background-position: -110px -75px;
}

i.gpfs-flag.cmr {
  width: 25px;
  height: 25px;
  background-position: -145px -75px;
}

i.gpfs-flag.can {
  width: 25px;
  height: 25px;
  background-position: -180px -75px;
}

i.gpfs-flag.cxr {
  width: 25px;
  height: 25px;
  background-image: url($base_url + '/images/cx.svg');
  background-size: cover;
  background-position: center;
}

i.gpfs-flag.rcb {
  width: 25px;
  height: 25px;
  background-image: url($base_url + '/images/cg.svg');
  background-size: cover;
  background-position: center;
}

i.gpfs-flag.cpv {
  width: 25px;
  height: 25px;
  background-position: -215px -75px;
}

i.gpfs-flag.cym {
  width: 25px;
  height: 25px;
  background-position: -250px -75px;
}

i.gpfs-flag.rca {
  width: 25px;
  height: 25px;
  background-position: -285px -75px;
}

i.gpfs-flag.tcd {
  width: 25px;
  height: 25px;
  background-position: -320px -75px;
}

i.gpfs-flag.chl {
  width: 25px;
  height: 25px;
  background-position: -355px -75px;
}

i.gpfs-flag.chn {
  width: 25px;
  height: 25px;
  background-position: -390px -75px;
}

i.gpfs-flag.col {
  width: 25px;
  height: 25px;
  background-position: -425px -75px;
}

i.gpfs-flag.cod {
  width: 25px;
  height: 25px;
  background-position: -460px -75px;
}

i.gpfs-flag.civ {
  width: 25px;
  height: 25px;
  background-position: -495px -75px;
}

i.gpfs-flag.cri {
  width: 25px;
  height: 25px;
  background-position: -5px -110px;
}

i.gpfs-flag.hrv {
  width: 25px;
  height: 25px;
  background-position: -40px -110px;
}

i.gpfs-flag.cub {
  width: 25px;
  height: 25px;
  background-position: -75px -110px;
}

i.gpfs-flag.cuw {
  width: 25px;
  height: 25px;
  background-position: -110px -110px;
}

i.gpfs-flag.cyp {
  width: 25px;
  height: 25px;
  background-position: -145px -110px;
}

i.gpfs-flag.cze {
  width: 25px;
  height: 25px;
  background-position: -180px -110px;
}

i.gpfs-flag.dnk {
  width: 25px;
  height: 25px;
  background-position: -215px -110px;
}

i.gpfs-flag.dji {
  width: 25px;
  height: 25px;
  background-position: -250px -110px;
}

i.gpfs-flag.dma {
  width: 25px;
  height: 25px;
  background-position: -285px -110px;
}

i.gpfs-flag.dom {
  width: 25px;
  height: 25px;
  background-position: -320px -110px;
}

i.gpfs-flag.tls {
  width: 25px;
  height: 25px;
  background-position: -355px -110px;
}

i.gpfs-flag.ecu {
  width: 25px;
  height: 25px;
  background-position: -390px -110px;
}

i.gpfs-flag.egy {
  width: 25px;
  height: 25px;
  background-position: -425px -110px;
}

i.gpfs-flag.slv {
  width: 25px;
  height: 25px;
  background-position: -460px -110px;
}

i.gpfs-flag.gnq {
  width: 25px;
  height: 25px;
  background-position: -495px -110px;
}

i.gpfs-flag.EUROPEAN_UNION_K_OL {
  width: 25px;
  height: 25px;
  background-position: -5px -145px;
}

i.gpfs-flag.eri {
  width: 25px;
  height: 25px;
  background-position: -40px -145px;
}

i.gpfs-flag.eth {
  width: 25px;
  height: 25px;
  background-position: -75px -145px;
}

i.gpfs-flag.est {
  width: 25px;
  height: 25px;
  background-position: -110px -145px;
}

i.gpfs-flag.fro {
  width: 25px;
  height: 25px;
  background-position: -145px -145px;
}

i.gpfs-flag.flk {
  width: 25px;
  height: 25px;
  background-position: -180px -145px;
}

i.gpfs-flag.fin {
  width: 25px;
  height: 25px;
  background-position: -215px -145px;
}

i.gpfs-flag.guf {
  width: 25px;
  height: 25px;
  background-position: -250px -145px;
}

i.gpfs-flag.fra {
  width: 25px;
  height: 25px;
  background-position: -285px -145px;
}

i.gpfs-flag.gab {
  width: 25px;
  height: 25px;
  background-position: -320px -145px;
}

i.gpfs-flag.GAZA_K_OL {
  width: 25px;
  height: 25px;
  background-position: -355px -145px;
}

i.gpfs-flag.gha {
  width: 25px;
  height: 25px;
  background-position: -390px -145px;
}

i.gpfs-flag.geo {
  width: 25px;
  height: 25px;
  background-position: -425px -145px;
}

i.gpfs-flag.deu {
  width: 25px;
  height: 25px;
  background-position: -460px -145px;
}

i.gpfs-flag.gib {
  width: 25px;
  height: 25px;
  background-position: -495px -145px;
}

i.gpfs-flag.grc {
  width: 25px;
  height: 25px;
  background-position: -5px -180px;
}

i.gpfs-flag.grl {
  width: 25px;
  height: 25px;
  background-position: -40px -180px;
}

i.gpfs-flag.grd {
  width: 25px;
  height: 25px;
  background-position: -75px -180px;
}

i.gpfs-flag.glp {
  width: 25px;
  height: 25px;
  background-position: -110px -180px;
}

i.gpfs-flag.gtm {
  width: 25px;
  height: 25px;
  background-position: -145px -180px;
}

i.gpfs-flag.ggy {
  width: 25px;
  height: 25px;
  background-position: -180px -180px;
}

i.gpfs-flag.gin {
  width: 25px;
  height: 25px;
  background-position: -215px -180px;
}

i.gpfs-flag.gnb {
  width: 25px;
  height: 25px;
  background-position: -250px -180px;
}

i.gpfs-flag.guy {
  width: 25px;
  height: 25px;
  background-position: -285px -180px;
}

i.gpfs-flag.hti {
  width: 25px;
  height: 25px;
  background-position: -320px -180px;
}

i.gpfs-flag.hnd {
  width: 25px;
  height: 25px;
  background-position: -355px -180px;
}

i.gpfs-flag.hkg {
  width: 25px;
  height: 25px;
  background-position: -390px -180px;
}

i.gpfs-flag.hun {
  width: 25px;
  height: 25px;
  background-position: -425px -180px;
}

i.gpfs-flag.isl {
  width: 25px;
  height: 25px;
  background-position: -460px -180px;
}

i.gpfs-flag.ind {
  width: 25px;
  height: 25px;
  background-position: -495px -180px;
}

i.gpfs-flag.idn {
  width: 25px;
  height: 25px;
  background-position: -5px -215px;
}

i.gpfs-flag.irn {
  width: 25px;
  height: 25px;
  background-position: -40px -215px;
}

i.gpfs-flag.irq {
  width: 25px;
  height: 25px;
  background-position: -75px -215px;
}

i.gpfs-flag.irl {
  width: 25px;
  height: 25px;
  background-position: -110px -215px;
}

i.gpfs-flag.isr {
  width: 25px;
  height: 25px;
  background-position: -145px -215px;
}

i.gpfs-flag.imn {
  width: 25px;
  height: 25px;
  background-position: -180px -215px;
}

i.gpfs-flag.ita {
  width: 25px;
  height: 25px;
  background-position: -215px -215px;
}

i.gpfs-flag.jam {
  width: 25px;
  height: 25px;
  background-position: -250px -215px;
}

i.gpfs-flag.jpn {
  width: 25px;
  height: 25px;
  background-position: -285px -215px;
}

i.gpfs-flag.jor {
  width: 25px;
  height: 25px;
  background-position: -320px -215px;
}

i.gpfs-flag.jey {
  width: 25px;
  height: 25px;
  background-position: -355px -215px;
}

i.gpfs-flag.kaz {
  width: 25px;
  height: 25px;
  background-position: -390px -215px;
}

i.gpfs-flag.ken {
  width: 25px;
  height: 25px;
  background-position: -425px -215px;
}

i.gpfs-flag.xkx {
  width: 25px;
  height: 25px;
  background-position: -460px -215px;
}

i.gpfs-flag.kgz {
  width: 25px;
  height: 25px;
  background-position: -495px -215px;
}

i.gpfs-flag.lao {
  width: 25px;
  height: 25px;
  background-position: -5px -250px;
}

i.gpfs-flag.lva {
  width: 25px;
  height: 25px;
  background-position: -40px -250px;
}

i.gpfs-flag.lbn {
  width: 25px;
  height: 25px;
  background-position: -75px -250px;
}

i.gpfs-flag.lso {
  width: 25px;
  height: 25px;
  background-position: -110px -250px;
}

i.gpfs-flag.lbr {
  width: 25px;
  height: 25px;
  background-position: -145px -250px;
}

i.gpfs-flag.lby {
  width: 25px;
  height: 25px;
  background-position: -180px -250px;
}

i.gpfs-flag.lie {
  width: 25px;
  height: 25px;
  background-position: -215px -250px;
}

i.gpfs-flag.ltu {
  width: 25px;
  height: 25px;
  background-position: -250px -250px;
}

i.gpfs-flag.lux {
  width: 25px;
  height: 25px;
  background-position: -285px -250px;
}

i.gpfs-flag.mac {
  width: 25px;
  height: 25px;
  background-position: -320px -250px;
}

i.gpfs-flag.mdg {
  width: 25px;
  height: 25px;
  background-position: -355px -250px;
}

i.gpfs-flag.mkd {
  width: 25px;
  height: 25px;
  background-position: -390px -250px;
}

i.gpfs-flag.mwi {
  width: 25px;
  height: 25px;
  background-position: -425px -250px;
}

i.gpfs-flag.mys {
  width: 25px;
  height: 25px;
  background-position: -460px -250px;
}

i.gpfs-flag.mdv {
  width: 25px;
  height: 25px;
  background-position: -495px -250px;
}

i.gpfs-flag.mlt {
  width: 25px;
  height: 25px;
  background-position: -5px -285px;
}

i.gpfs-flag.mtq {
  width: 25px;
  height: 25px;
  background-position: -40px -285px;
}

i.gpfs-flag.mli {
  width: 25px;
  height: 25px;
  background-position: -75px -285px;
}

i.gpfs-flag.mrt {
  width: 25px;
  height: 25px;
  background-position: -110px -285px;
}

i.gpfs-flag.mus {
  width: 25px;
  height: 25px;
  background-position: -145px -285px;
}

i.gpfs-flag.mex {
  width: 25px;
  height: 25px;
  background-position: -180px -285px;
}

i.gpfs-flag.mda {
  width: 25px;
  height: 25px;
  background-position: -215px -285px;
}

i.gpfs-flag.mng {
  width: 25px;
  height: 25px;
  background-position: -250px -285px;
}

i.gpfs-flag.mne {
  width: 25px;
  height: 25px;
  background-position: -285px -285px;
}

i.gpfs-flag.msr {
  width: 25px;
  height: 25px;
  background-position: -320px -285px;
}

i.gpfs-flag.mar {
  width: 25px;
  height: 25px;
  background-position: -355px -285px;
}

i.gpfs-flag.moz {
  width: 25px;
  height: 25px;
  background-position: -390px -285px;
}

i.gpfs-flag.mmr {
  width: 25px;
  height: 25px;
  background-position: -425px -285px;
}

i.gpfs-flag.nam {
  width: 25px;
  height: 25px;
  background-position: -460px -285px;
}

i.gpfs-flag.npl {
  width: 25px;
  height: 25px;
  background-position: -495px -285px;
}

i.gpfs-flag.nld {
  width: 25px;
  height: 25px;
  background-position: -5px -320px;
}

i.gpfs-flag.nzl {
  width: 25px;
  height: 25px;
  background-position: -40px -320px;
}

i.gpfs-flag.ner {
  width: 25px;
  height: 25px;
  background-position: -75px -320px;
}

i.gpfs-flag.nga {
  width: 25px;
  height: 25px;
  background-position: -110px -320px;
}

i.gpfs-flag.nic {
  width: 25px;
  height: 25px;
  background-position: -145px -320px;
}

i.gpfs-flag.niu {
  width: 25px;
  height: 25px;
  background-position: -180px -320px;
}

i.gpfs-flag.ant {
  width: 25px;
  height: 25px;
  background-position: -215px -320px;
}

i.gpfs-flag.prk {
  width: 25px;
  height: 25px;
  background-position: -250px -320px;
}

i.gpfs-flag.nor {
  width: 25px;
  height: 25px;
  background-position: -285px -320px;
}

i.gpfs-flag.omn {
  width: 25px;
  height: 25px;
  background-position: -320px -320px;
}

i.gpfs-flag.pak {
  width: 25px;
  height: 25px;
  background-position: -355px -320px;
}

i.gpfs-flag.pse {
  width: 25px;
  height: 25px;
  background-position: -390px -320px;
}

i.gpfs-flag.pan {
  width: 25px;
  height: 25px;
  background-position: -425px -320px;
}

i.gpfs-flag.pry {
  width: 25px;
  height: 25px;
  background-position: -460px -320px;
}

i.gpfs-flag.per {
  width: 25px;
  height: 25px;
  background-position: -495px -320px;
}

i.gpfs-flag.phl {
  width: 25px;
  height: 25px;
  background-position: -5px -355px;
}

i.gpfs-flag.pol {
  width: 25px;
  height: 25px;
  background-position: -40px -355px;
}

i.gpfs-flag.prt {
  width: 25px;
  height: 25px;
  background-position: -75px -355px;
}

i.gpfs-flag.pri {
  width: 25px;
  height: 25px;
  background-position: -110px -355px;
}

i.gpfs-flag.qat {
  width: 25px;
  height: 25px;
  background-position: -145px -355px;
}

i.gpfs-flag.cog {
  width: 25px;
  height: 25px;
  background-position: -180px -355px;
}

i.gpfs-flag.reu {
  width: 25px;
  height: 25px;
  background-position: -215px -355px;
}

i.gpfs-flag.rou {
  width: 25px;
  height: 25px;
  background-position: -250px -355px;
}

i.gpfs-flag.rus {
  width: 25px;
  height: 25px;
  background-position: -285px -355px;
}

i.gpfs-flag.rwa {
  width: 25px;
  height: 25px;
  background-position: -320px -355px;
}

i.gpfs-flag.sau {
  width: 25px;
  height: 25px;
  background-position: -355px -355px;
}

i.gpfs-flag.sgp {
  width: 25px;
  height: 25px;
  background-position: -390px -355px;
}

i.gpfs-flag.kor {
  width: 25px;
  height: 25px;
  background-position: -425px -355px;
}

i.gpfs-flag.lka {
  width: 25px;
  height: 25px;
  background-position: -460px -355px;
}

i.gpfs-flag.syr {
  width: 25px;
  height: 25px;
  background-position: -495px -355px;
}

i.gpfs-flag.smr {
  width: 25px;
  height: 25px;
  background-position: -5px -390px;
}

i.gpfs-flag.stp {
  width: 25px;
  height: 25px;
  background-position: -40px -390px;
}

i.gpfs-flag.sen {
  width: 25px;
  height: 25px;
  background-position: -75px -390px;
}

i.gpfs-flag.srb {
  width: 25px;
  height: 25px;
  background-position: -110px -390px;
}

i.gpfs-flag.syc {
  width: 25px;
  height: 25px;
  background-position: -145px -390px;
}

i.gpfs-flag.sle {
  width: 25px;
  height: 25px;
  background-position: -180px -390px;
}

i.gpfs-flag.maf {
  width: 25px;
  height: 25px;
  background-position: -215px -390px;
}

i.gpfs-flag.svk {
  width: 25px;
  height: 25px;
  background-position: -250px -390px;
}

i.gpfs-flag.svn {
  width: 25px;
  height: 25px;
  background-position: -285px -390px;
}

i.gpfs-flag.som {
  width: 25px;
  height: 25px;
  background-position: -320px -390px;
}

i.gpfs-flag.zaf {
  width: 25px;
  height: 25px;
  background-position: -355px -390px;
}

i.gpfs-flag.sgs {
  width: 25px;
  height: 25px;
  background-position: -390px -390px;
}

i.gpfs-flag.esp {
  width: 25px;
  height: 25px;
  background-position: -425px -390px;
}

i.gpfs-flag.kna {
  width: 25px;
  height: 25px;
  background-position: -460px -390px;
}

i.gpfs-flag.lca {
  width: 25px;
  height: 25px;
  background-position: -495px -390px;
}

i.gpfs-flag.vct {
  width: 25px;
  height: 25px;
  background-position: -5px -425px;
}

i.gpfs-flag.sdn {
  width: 25px;
  height: 25px;
  background-position: -40px -425px;
}

i.gpfs-flag.sur {
  width: 25px;
  height: 25px;
  background-position: -75px -425px;
}

i.gpfs-flag.swz {
  width: 25px;
  height: 25px;
  background-position: -110px -425px;
}

i.gpfs-flag.swe {
  width: 25px;
  height: 25px;
  background-position: -145px -425px;
}

i.gpfs-flag.che {
  width: 25px;
  height: 25px;
  background-position: -180px -425px;
}

i.gpfs-flag.tjk {
  width: 25px;
  height: 25px;
  background-position: -215px -425px;
}

i.gpfs-flag.twn {
  width: 25px;
  height: 25px;
  background-position: -250px -425px;
}

i.gpfs-flag.tza {
  width: 25px;
  height: 25px;
  background-position: -285px -425px;
}

i.gpfs-flag.tha {
  width: 25px;
  height: 25px;
  background-position: -320px -425px;
}

i.gpfs-flag.com {
  width: 25px;
  height: 25px;
  background-position: -355px -425px;
}

i.gpfs-flag.gmb {
  width: 25px;
  height: 25px;
  background-position: -390px -425px;
}

i.gpfs-flag.tgo {
  width: 25px;
  height: 25px;
  background-position: -425px -425px;
}

i.gpfs-flag.ton {
  width: 25px;
  height: 25px;
  background-position: -460px -425px;
}

i.gpfs-flag.tto {
  width: 25px;
  height: 25px;
  background-position: -495px -425px;
}

i.gpfs-flag.tun {
  width: 25px;
  height: 25px;
  background-position: -5px -460px;
}

i.gpfs-flag.tur {
  width: 25px;
  height: 25px;
  background-position: -40px -460px;
}

i.gpfs-flag.tkm {
  width: 25px;
  height: 25px;
  background-position: -75px -460px;
}

i.gpfs-flag.tca {
  width: 25px;
  height: 25px;
  background-position: -110px -460px;
}

i.gpfs-flag.tuv {
  width: 25px;
  height: 25px;
  background-position: -145px -460px;
}

i.gpfs-flag.uga {
  width: 25px;
  height: 25px;
  background-position: -180px -460px;
}

i.gpfs-flag.ukr {
  width: 25px;
  height: 25px;
  background-position: -215px -460px;
}

i.gpfs-flag.are {
  width: 25px;
  height: 25px;
  background-position: -250px -460px;
}

i.gpfs-flag.gbr {
  width: 25px;
  height: 25px;
  background-position: -285px -460px;
}

i.gpfs-flag.usa {
  width: 25px;
  height: 25px;
  background-position: -320px -460px;
}

i.gpfs-flag.ury {
  width: 25px;
  height: 25px;
  background-position: -355px -460px;
}

i.gpfs-flag.uzb {
  width: 25px;
  height: 25px;
  background-position: -390px -460px;
}

i.gpfs-flag.vut {
  width: 25px;
  height: 25px;
  background-position: -425px -460px;
}

i.gpfs-flag.ven {
  width: 25px;
  height: 25px;
  background-position: -460px -460px;
}

i.gpfs-flag.vnm {
  width: 25px;
  height: 25px;
  background-position: -495px -460px;
}

i.gpfs-flag.vir {
  width: 25px;
  height: 25px;
  background-position: -5px -495px;
}

i.gpfs-flag.yem {
  width: 25px;
  height: 25px;
  background-position: -40px -495px;
}

i.gpfs-flag.zmb {
  width: 25px;
  height: 25px;
  background-position: -75px -495px;
}

i.gpfs-flag.zwe {
  width: 25px;
  height: 25px;
  background-position: -110px -495px;
}

i.gpfs-flag.bgd {
  width: 25px;
  height: 25px;
  background-position: -145px -495px;
}

i.gpfs-flag.fji {
  width: 25px;
  height: 25px;
  background-position: -180px -495px;
}

i.gpfs-flag.wsm {
  width: 25px;
  height: 25px;
  background-position: -215px -495px;
}

i.gpfs-flag.kwt {
  width: 25px;
  height: 25px;
  background-position: -250px -495px;
}

i.gpfs-flag.mco {
  width: 25px;
  height: 25px;
  background-position: -320px -495px;
}

i.gpfs-flag.asm {
  width: 25px;
  height: 25px;
  background-position: -355px -495px;
}

i.gpfs-flag.ata {
  width: 25px;
  height: 25px;
  background-position: -390px -495px;
}

i.gpfs-flag.iot {
  width: 25px;
  height: 25px;
  background-position: -425px -495px;
}

i.gpfs-flag.cck {
  width: 25px;
  height: 25px;
  background-position: -460px -495px;
}

i.gpfs-flag.cok {
  width: 25px;
  height: 25px;
  background-position: -495px -495px;
}

i.gpfs-flag.nru {
  width: 25px;
  height: 25px;
  background-position: -530px -5px;
}

i.gpfs-flag.pyf {
  width: 25px;
  height: 25px;
  background-position: -530px -40px;
}

i.gpfs-flag.atf {
  width: 25px;
  height: 25px;
  background-position: -530px -75px;
}

i.gpfs-flag.gum {
  width: 25px;
  height: 25px;
  background-position: -530px -110px;
}

i.gpfs-flag.hmd {
  width: 25px;
  height: 25px;
  background-position: -530px -145px;
}

i.gpfs-flag.vat {
  width: 25px;
  height: 25px;
  background-position: -530px -180px;
}

i.gpfs-flag.kir {
  width: 25px;
  height: 25px;
  background-position: -530px -215px;
}

i.gpfs-flag.myt {
  width: 25px;
  height: 25px;
  background-position: -530px -250px;
}

i.gpfs-flag.fsm {
  width: 25px;
  height: 25px;
  background-position: -530px -285px;
}

i.gpfs-flag.ncl {
  width: 25px;
  height: 25px;
  background-position: -530px -320px;
}

i.gpfs-flag.nfk {
  width: 25px;
  height: 25px;
  background-position: -530px -355px;
}

i.gpfs-flag.mnp {
  width: 25px;
  height: 25px;
  background-position: -530px -390px;
}

i.gpfs-flag.png {
  width: 25px;
  height: 25px;
  background-position: -530px -425px;
}

i.gpfs-flag.pcn {
  width: 25px;
  height: 25px;
  background-position: -530px -460px;
}

i.gpfs-flag.mhl {
  width: 25px;
  height: 25px;
  background-position: -530px -495px;
}

i.gpfs-flag.slb {
  width: 25px;
  height: 25px;
  background-position: -5px -530px;
}

i.gpfs-flag.shn {
  width: 25px;
  height: 25px;
  background-position: -40px -530px;
}

i.gpfs-flag.spm {
  width: 25px;
  height: 25px;
  background-position: -75px -530px;
}

i.gpfs-flag.tkl {
  width: 25px;
  height: 25px;
  background-position: -110px -530px;
}

i.gpfs-flag.umi {
  width: 25px;
  height: 25px;
  background-position: -145px -530px;
}

i.gpfs-flag.wlf {
  width: 25px;
  height: 25px;
  background-position: -180px -530px;
}

i.gpfs-flag.plw {
  width: 25px;
  height: 25px;
  background-position: -215px -530px;
}

i.gpfs-flag.esh {
  width: 25px;
  height: 25px;
  background-position: -250px -530px;
}

i.gpfs-flag.ssd {
  width: 25px;
  height: 25px;
  background-position: -285px -530px;
}
/* mapping svalbard and Jan Mayen island to norwegian flag */
i.gpfs-flag.sjm {
  width: 25px;
  height: 25px;
  background-position: -285px -320px;
}
