.ppc-info-container {
  @apply flex;
  @apply bg-accentLight p-4 text-accentDark;

  ul {
    list-style: disc;
    padding-inline-start: 1em;
    @apply text-accentDark;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}