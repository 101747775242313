html {
  font-size: 14px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./style/flags.scss);
@import url(./style/buyer.scss);


body {
  margin: 0;
  font-family: var(--custom-font-name);
  @apply text-midnight;
}

:host {
  @apply text-midnight;
  font-size: 14px;
  font-family: var(--custom-font-name);
}