.pdfPage {
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.doc-info-container {
    @apply flex;
    @apply bg-accentLight p-4 text-accentDark;

    ul {
        list-style: disc;
        padding-inline-start: 1em;
        @apply text-accentDark;
    }
}